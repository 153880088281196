import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import '@babel/polyfill'

import { Auth0Plugin, useAuth0 } from '@/auth/auth0-plugin'
import { formatCents, formatDate, formatDateOnly, formatPhone } from '@/utils'

Vue.use(Auth0Plugin, {
  audience: process.env.VUE_APP_AUTH_AUDIENCE_ADMIN_API,
  domain: process.env.VUE_APP_AUTH_DOMAIN,
  clientId: process.env.VUE_APP_AUTH_CLIENT_ID,
  onRedirectCallback: useAuth0.onRedirectCallback
})

Vue.config.productionTip = false

Vue.filter('date', formatDate)
Vue.filter('dateOnly', formatDateOnly)
Vue.filter('cents', formatCents)
Vue.filter('phone', formatPhone)

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
