/**
 * @param val {Number} Value in cents
 * @returns {String} string value in 2 decimal format
 */
export function centsToDollars (val) {
  if (isNaN(val)) return ''
  return (val * 0.01).toFixed(2)
}

/**
 * @param val {Number} Value in decimal
 * @returns {String} string percent value in 2 decimal format
 */
export function decimalToPercent (val) {
  if (isNaN(val)) return ''
  return (val * 100).toFixed(2)
}

/**
 * @param val {Number} Value in cents
 * @returns {String} string value in 2 decimal format
 */
export function dollarsToCents (val) {
  if (isNaN(val)) return ''
  return (val * 100).toFixed(2)
}

/**
 * @param val {String} ISO Date Format
 * @returns {String} mm/dd/yyyy
 */
export function formatCents (val) {
  if (!val) return ''
  const d = centsToDollars(parseInt(val))
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(parseFloat(d))
}

/**
 * @param val {String} ISO Date Format
 * @returns {String} mm/dd/yyyy
 */
export function formatDate (val) {
  if (!val) return ''
  const d = new Date(val)
  return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`
}

export function formatDateOnly (val) {
  if (!val) return ''
  const dd = val.substring(5, 7)
  const mm = val.substring(5, 7)
  const yyyy = val.substring(8, 10)
  return `${mm}/${dd}/${yyyy}`
}

/**
 * @param val {String}
 * @returns {String} (###) ###-####
 */
export function formatPhone (val) {
  if (!val) return ''
  return val
    .replace(/[^0-9]/g, '')
    .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
}

/**
 * @param val {String}
 * @returns {Date} (###) ###-####
 */
export function parseDate (val) {
  if (!val) return null
  return new Date(Date.parse(val))
}

/**
 * @param val {Number} Value in decimal
 * @returns {String} string percent value to 4 decimal places
 */
export function percentToDecimal (val) {
  if (isNaN(val)) return 0
  return (val * 0.01).toFixed(4)
}

/**
 * @param val {Number} Value in seconds
 * @returns {String} value in 2 decimal format
 */
export function secondsToHours (val) {
  if (isNaN(val)) return ''
  return (val / 60 / 60).toFixed(2)
}

/**
 * @param k<string> Key or Property Name
 * @param arg<any> Object | Map | Set
 */
export function valueFromKey (k, arg) {
  if (arg[k] === undefined) {
    throw new InvalidKeyError(`Key '${k}' not found`)
  }
  return arg[k]
}

export class InvalidKeyError extends Error {
  constructor (msg) {
    super(msg)
    this.name = 'InvalidKeyError'
  }
}
