export const icons = {
  add: 'fas fa-plus',
  back: 'fas fa-chevron-circle-left',
  calendar: 'fas fa-calendar-alt',
  callSession: 'fa-sold fa-headset',
  cancel: 'fas fa-ban',
  comments: 'far fa-comments',
  currency: 'fas fa-dollar-sign',
  dashboard: 'fas fa-border-all',
  delete: 'fas fa-trash',
  doNotCall: 'fas fa-phone-slash',
  edit: 'fas fa-pencil',
  email: 'fas fa-envelope',
  eye: 'fas fa-eye',
  fileDownload: 'fas fa-file-download',
  fileCode: 'fas fa-file-code',
  fileCsv: 'far fa-file-csv',
  fileImport: 'fas fa-file-import',
  image: 'fas fa-image',
  kpiTarget: 'fas fa-bullseye',
  left: 'fas fa-chevron-left',
  navMenu: 'fas fa-bars',
  no: 'fas fa-ban',
  percent: 'fas fa-percent',
  phone: 'fas fa-phone',
  profile: 'fas fa-user',
  repairOrder: 'fas fa-tools',
  reports: 'fas fa-poll',
  right: 'fas fa-chevron-right',
  save: 'fas fa-save',
  search: 'fas fa-search',
  shop: 'fas fa-car',
  signOut: 'fas fa-sign-out-alt',
  undo: 'fas fa-undo',
  url: 'fas fa-globe',
  users: 'fas fa-users',
  yes: 'fas fa-check'
}

export const text = {
  actionNeeded: 'Action Needed - Time Sensitive',
  add: 'Add',
  address: 'Address',
  adminUser: 'Admin User',
  all: 'All',
  addShop: 'Add Shop',
  ambassador: 'Ambassador',
  appName: 'Admin',
  assignedTo: 'Assigned To',
  attempts: 'Attempts',
  back: 'Back',
  billable: 'Billable',
  birthday: 'Birthday',
  calledAt: 'Called At',
  callSession: 'Call Session',
  callSessions: 'Call Sessions',
  cancel: 'Cancel',
  city: 'City',
  comments: 'Comments',
  completed: 'Completed',
  connection: 'Connection',
  connectionRate: 'Connection Rate',
  connections: 'Connections',
  crossStreets: 'Cross Streets',
  customer: 'Customer',
  customers: 'Customers',
  date: 'Date',
  deleteOk: 'Record was deleted successfully!',
  details: 'Details',
  doNotCall: 'Do Not Call',
  doNotCallInternal: 'Do Not Call - Internal',
  download: 'Download',
  duplicate: 'Duplicate',
  edit: 'Edit',
  email: 'Email',
  emailReviewLink: 'Email Review Link to Customer',
  emailShopImmediately: 'Email Shop Immediately',
  employee: 'Employee',
  end: 'End',
  errorGeneric:
    'Something went wrong; please contact our team so we can correct the problem',
  externalId: 'External Id',
  externalSystem: 'External System',
  file: 'File',
  firstName: 'First Name',
  followUp: 'Follow Up',
  followUps: 'Follow Ups',
  followUpTotals: 'Follow Up Totals',
  headsUp: 'Heads Up',
  high: 'High',
  highlight: 'Highlight',
  homePage: 'Home Page',
  import: 'Import',
  importFileCsv: 'CSV Import File',
  importFileXml: 'XML Import File',
  inProgress: 'In Progress',
  invalid: 'Invalid',
  kpiTarget: 'KPI Target',
  kpiTargets: 'KPI Targets',
  lastName: 'Last Name',
  leftVm: 'Left Voicemail',
  logo: 'Logo',
  logoUrl: 'Logo URL',
  low: 'Low',
  medium: 'Medium',
  mine: 'Mine',
  name: 'Name',
  negative: 'Negative',
  negativeFeedback: 'Negative Feedback',
  neutral: 'Neutral',
  noConnection: 'No Connection',
  nonBillable: 'Non Billable',
  noPhoneNumber: 'No Phone Number',
  noResult: 'No Result',
  notes: 'Notes',
  ok: 'Ok',
  opportunity: 'Revenue Opportunity',
  positive: 'Positive',
  phone: 'Phone',
  phoneNumbers: 'Phone Numbers',
  postalCode: 'Zip',
  preferences: 'Preferences',
  readOnly: 'This value is cannot be changed',
  repairOrder: 'Repair Order',
  repairOrders: 'Repair Orders',
  reports: 'Reports',
  required: 'Required',
  requiredMsg: 'Please fill in all required fields',
  result: 'Result',
  reviewCommitted: 'Review Committed',
  reviewRequested: 'Review Asked',
  save: 'Save',
  saveNext: 'Save and Next',
  saveOk: 'Your changes were saved successfully!',
  scheduledAppointment: 'Appointment Scheduled - In Process',
  search: 'Search',
  serviceAdvisor: 'Service Advisor',
  shop: 'Shop',
  shopDetails: 'Shop Details',
  shopNotes: 'Shop Notes',
  shops: 'Shops',
  signOut: 'Sign Out',
  skip: 'Skip',
  start: 'Start',
  state: 'State',
  status: 'Status',
  submit: 'Submit',
  tekmetricId: 'Tekmetric Id',
  template: 'Template',
  total: 'Total',
  type: 'Type',
  undo: 'Undo',
  upload: 'Upload',
  user: 'User',
  users: 'Users',
  vehicle: 'Vehicle',
  view: 'View',
  vm: 'Voice Mail',
  yes: 'Yes'
}
